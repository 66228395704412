<template>
  <with-parallax-heading>
    <template #title>
      Who are we?
    </template>

    <template #caption>
      {{ labels.PH_ABOUT_QUOTE }}
    </template>

    <div id="about" class="about-us">
      <div class="container default-padding">
        <div class="primary-heading text-center">
          {{ labels.MENU_ABOUT }}
        </div>

        <div class="caption text-center mb-6">{{ labels.PH_ABOUT_QUOTE }}</div>

        <div>
          <!-- <img
            :src="require(`@/assets/images/defaults/smart-house.png`)"
            alt=""
            class="about-us__image"
          /> -->

          <div class="paragraph">
            SoftWeb Developers is an independent, modern Software Development and Web Design & Development company. An IT company located in clean green city of Nepal, Hetauda. We are providing IT services in Software Development, Web Designing & Development, Business Intelligence Solutions along with Domain Registration, Web Hosting and SEO Services. It is product as well as service based company.
          </div>
        </div>
      </div>
    </div>

    <div id="vision" class="vision">
      <div class="container default-padding">
        <div class="primary-heading text-center" style="color: white">
          {{ labels.LBL_VISION }}
        </div>

        <div class="caption text-center mb-6">{{ labels.PH_ABOUT_QUOTE }}</div>

        <!-- <img
          :src="require(`@/assets/images/defaults/smart-house.png`)"
          alt=""
          class="vision__image"
        /> -->

        <div class="paragraph">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate
          magnam perferendis doloribus vero magni fugiat, doloremque molestiae
          porro eius sequi nemo, necessitatibus, sunt dicta rerum ab voluptates!
          Inventore neque tempora veniam animi ducimus cumque ipsum praesentium,
          repellat ratione quaerat totam assumenda id sint! Harum iste est illo
          voluptates labore optio neque reiciendis explicabo consequatur
          accusantium nobis nemo deleniti nihil saepe, excepturi voluptas
          laborum modi repudiandae sunt ipsam natus deserunt officiis inventore
          dignissimos. Illum incidunt nemo asperiores, sint quod blanditiis sunt
          magnam quia quos laboriosam repellat non sapiente, nulla repudiandae
          ex tenetur mollitia ratione eius, ab optio laborum maxime sed
          assumenda! Ea, doloremque. Iure cumque, dolores unde eum magni quae.
          Nisi eos quos libero dolore animi? Est exercitationem voluptas sunt
          asperiores, quo molestiae doloremque ad ratione consectetur nisi ex
          rerum cupiditate quas ipsum? Ipsa natus in alias sapiente id
          praesentium sed, ipsam repellat, odio nemo voluptate harum
          necessitatibus a nulla! Obcaecati dolor quod beatae impedit provident
          eveniet, deserunt fuga deleniti, perspiciatis blanditiis voluptas
          sequi reiciendis voluptate expedita minima sed ipsa adipisci veritatis
          quis esse magnam! Cumque adipisci et quasi, unde, eaque cum atque
          doloremque incidunt voluptas soluta iure. Facere, rem, facilis alias
          ea maiores magni, impedit rerum sunt neque vel repudiandae!
        </div>
      </div>
    </div>

    <div class="container default-padding">
      <tools-and-tech />
    </div>
  </with-parallax-heading>
</template>

<script>
  import { labels } from "@/common";
  import WithParallaxHeading from "@/components/WithParallaxHeading";
  import ToolsAndTech from "@/components/ToolsAndTech.vue";

  export default {
    name: "About",

    components: { WithParallaxHeading, ToolsAndTech },

    data() {
      return {
        labels,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  .about-us,
  .vision {
    padding-bottom: 2rem;
    // min-height: 600px;

    &__image {
      height: 400px;
      margin-bottom: 2rem;
      object-fit: cover;
    }
  }

  .about-us {
    &__image {
      width: 50%;
      margin-right: 4rem;
      float: left;
    }
  }

  .vision {
    background: $background-color;
    color: white;
    &__image {
      width: 50%;
      margin-left: 4rem;
      float: right;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .about-us,
    .vision {
      &__image {
        height: 200px;
        float: none;
        width: 100%;
        margin: 0;
        margin-bottom: 2rem;
      }
    }
  }
</style>
